export const firebaseConfig = {
    apiKey: "AIzaSyBHqFNswZ31kb5DGnTFcgD8HrfmnNI52eE",
    authDomain: "maystra-dev.firebaseapp.com",
    projectId: "maystra-dev",
    databaseURL: "https://maystra-dev.firebaseio.com",
    storageBucket: "maystra-dev.appspot.com",
    messagingSenderId: "1014456832955",
    appId: "1:1014456832955:web:592b522a415803e41eaa9e",
    measurementId: "G-24Z8HJ7YRJ"
};


export const firebaseDevConfig = {
    apiKey: "AIzaSyBHqFNswZ31kb5DGnTFcgD8HrfmnNI52eE",
    authDomain: "maystra-dev.firebaseapp.com",
    projectId: "maystra-dev",
    databaseURL: "localhost:8081",
    storageBucket: "maystra-dev.appspot.com",
    messagingSenderId: "1014456832955",
    appId: "1:1014456832955:web:592b522a415803e41eaa9e",
    measurementId: "G-24Z8HJ7YRJ",
};
