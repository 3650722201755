import firebase from 'firebase/app';
import 'firebase/auth';
import { firebaseConfig, firebaseDevConfig } from 'src/config';

if (!firebase.apps.length) {
  if (window.location.hostname === "localhost") {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.initializeApp(firebaseConfig);
  }
}

export default firebase;
