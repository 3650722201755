import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import 'src/mixins/chartjs';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';

ReactDOM.render(
    <SettingsProvider>
      <App />
    </SettingsProvider>
  ,
  document.getElementById('root')
);

serviceWorker.register();
