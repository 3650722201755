import firebase from "./firebase";
import 'firebase/firestore';
import 'firebase/functions';
import type { Product, ProductView } from 'src/types/product';
import type { Price } from "src/types/price";
import type { User } from 'src/types/user';

const db = firebase.firestore();

const funcs = firebase.app().functions("europe-west3");
if (window.location.hostname === "localhost") {
    funcs.useFunctionsEmulator("http://localhost:5001");
    db.settings({
        host: "localhost:8081",
        ssl: false
    });
    firebase.auth().useEmulator("http://localhost:9099/");
}

const productsDB = db.collection("@products");
// const getServerTime = () => firebase.firestore.FieldValue.serverTimestamp();
async function getProductView(url: string, getCompetitors?: boolean): Promise<ProductView | null> {
    const getProductFunc = funcs.httpsCallable("getProductView");
    try {
        const response = await getProductFunc({
            productURL: url,
            getCompetitors,
        });

        const product = response.data as ProductView;

        return product;
    } catch (error) {
        console.error("Can't fetch product view:", error)
        return null;
    }
}

async function getProductByID(id: string): Promise<Product | null> {
    try {
        const dbProduct = await productsDB.doc(id).get();
        if(dbProduct) {
            const dbProductData = dbProduct.data() as Product;
            const product:Product = {
                ...dbProductData,
                id: dbProduct.id
            }
            return product;
        }
        return null;
    } catch (error) {
        console.error("Can't fetch product:", error)
        return null;
    }
}

async function addProduct(productView: ProductView) : Promise<any> { //product'la beraber userid de gidecek
    const addProductFunc = funcs.httpsCallable("addProduct");
    const userID = firebase.auth().currentUser.uid;
    try {
        const response = await addProductFunc({productView, userID});

        return response.data;
    } catch (error) {
        console.error(error);

        return null;
    }
}

async function fetchUserProducts(): Promise<Product[]> {
    const userID = firebase.auth().currentUser.uid;
    const getProductFunc = funcs.httpsCallable("getUserProducts");
    try {
        const response = await getProductFunc({userID});
        const results = response.data;

        return results.map(data => {            
            const product: Product = {
                id: data.id,
                attributes: ['Cotton'],
                url: data["url"],
                category: 'dress',
                createdOn: data["createdOn"],
                imgSrc: data["imgSrc"],
                inventoryType: 'in_stock',
                isAvailable: true,
                name: data["name"],
                price: data["price"],
                quantity: 85,
                updatedOn: data["updatedOn"],
                variants: 1,
                competitorIDs: data["competitorIDs"],
                seller: data["seller"]
            }
            return product;
        });

    } catch (error) {
        return null;
    }
}

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
async function fetchPrices(productID: string, since?: Date): Promise<Price[]> {
    const results = await productsDB.doc(productID).collection("@prices").where("createdOn", ">=", since).get();

    return results.docs.map(doc => {
        const data = doc.data();
        
        const price: Price = {
            id: doc.id,
            createdOn: data["createdOn"].toDate(),
            value: data["value"] || data["price"],
            currency: data["currency"]
        }

        return price;
    });
}

async function getUser(userID: string): Promise<User> {
    return (await firebase.firestore()
        .collection("@users")
        .doc(userID).get()).data() as User;
}

async function updateUser(user: User): Promise<void> {
    await firebase.firestore()
        .collection("@users")
        .doc(user.id).set(user);
}

export { db, fetchUserProducts, fetchPrices, addProduct, getProductView, getUser, updateUser, getProductByID };